html {
  /* background-image: url('https://c0.wallpaperflare.com/preview/733/889/238/minimal-wallpaper-light-blue-clean-wallpaper-water.jpg');
  background-size: cover; */
  background-color: rgb(180, 228, 250);
}
.sp-img img {
  width: 20vw;
  height: 20vw;
  object-fit: contain;
}
.small-project,
.small-education {
  display: flex;
  flex-direction: column;
  width: 20vw;
  /* height: 60vh; */
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid black;
  border-radius: 15px;
  margin: 2vh 0;
}
.small-project:hover,
.skill:hover,
.lp-github:hover,
.vm-text:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.displayed-projects {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  border-top: 1px solid black;
}
.displayed-education {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  border-top: 1px solid black;
  /* border-bottom: 1px solid black; */
}
.view-more {
  width: 80vw;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
}
.vm-text {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid black;
  border-radius: 15px;
  margin: 2vh 0;
  padding: 0 1vh;
}
.sp-tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.sp-tech img {
  height: 3vh;
  margin: 1vh 2vh;
}
.sp-tech img:hover,
.socials img:hover {
  transform: scale(1.3);
}
.avery {
  display: flex;
  margin-left: -20vw;
  margin-top: 5vh;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2vh;
  border-bottom: 1px solid black;
}
.avery img {
  width: 20vw;
  border: 2px solid black;
  border-radius: 50%;
}

.a-img-text {
  display: flex;
  text-align: center;
  align-items: center;
}
.socials img {
  border: none;
  border-radius: 0;
  width: 2vw;
}

.socials {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4vh;
}
.a-text h1 {
  font-size: 50px;
  margin: 0;
}
.a-text p {
  margin: 2vh 0;
}
.a-text h2 {
  font-style: italic;
  margin: 4vh 0 2vh 0;
}
.a-text {
  margin-left: 3vw;
}

.top-projects,
.skills,
.large-project,
.education {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#american {
  text-align: left;
  margin-left: 1vw;
  border-bottom: 0;
}
span {
  text-decoration: underline;
  font-weight: bold;
}
nav {
  display: flex;
  justify-content: space-between;
  margin: 0vh 2vw;
  height: 5vh;
  align-items: center;
}

header {
  border-bottom: 1px solid black;
  width: 100vw;
  height: 5vh;
  /* background-color: rgb(180, 228, 250);

  background-color: rgba(255, 255, 255, 0.7); */
  background-color: white;
  /* background-color: rgb(140, 179, 196); */

  position: fixed;
  top: 0;
}
.nav-right {
  display: flex;
  width: 15vw;
  justify-content: space-between;
}

.sp-title,
.sp-img {
  border-bottom: 1px solid black;
}

.home-title {
  width: 72vw;
  margin-top: 2vw;
}
.home-title p,
.home-title h5 {
  margin: 0;
  font-style: italic;
}
.home-title p {
  margin: 1vh 0;
}
.home-title h1 {
  margin-bottom: 0;
}
.displayed-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 75vw;
  padding: 0 2.5vw;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.skill-img img {
  height: 7vh;
}

.skill {
  margin: 1vh 1vh;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1vh;
  border-radius: 15px;
  border: 1px solid black;
}
.sp-description {
  border-bottom: 1px black solid;
}
.skill p {
  margin: 0;
}

a {
  color: black;
  font-size: 20px;
  text-decoration: none;
}
a:hover {
  /* color: darkgray; */
  text-decoration: underline;
}
a:active {
  color: rgb(69, 69, 237);
}

.displayed-all-projects {
  display: flex;
  /* border-bottom: 1px solid black; */
  border-top: 1px solid black;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
}

.lp-github img {
  width: 4vw;
}
.lp-github {
  text-align: center;
}
a p {
  font-size: 16px;
}
#lp-title-git {
  display: flex;
  width: 72vw;
  justify-content: space-between;
  align-items: center;
}
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(140, 179, 196);
  border-top: 1px solid black;
  margin-top: 5vh;
  font-style: italic;
}
footer p {
  margin: 0.5vh 0;
}
.f-contact {
  display: flex;
  width: 40vw;
  justify-content: space-evenly;
}
.footer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
}

#footer {
  width: 80vw;
  margin-top: 1vh;
}
.lp-images {
  display: flex;
  border-bottom: 1px solid black;
  justify-content: space-evenly;
  display: flex;
  width: 70vw;
}

.lp-img img {
  width: 30vw;
  height: 30vw;
  object-fit: contain;
}

.lp-description {
  width: 60vw;
  font-weight: normal;
}
.section-cont {
  border-bottom: 1px solid black;
  width: 72vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#bio {
  border-top: 1px solid black;
  border-bottom: none;
}
.lp-description h3 {
  font-weight: normal;
}
.lp-description p {
  text-align: end;
}

.lp-title-images {
  /* width: 80vw; */
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
}
.section-title {
  /* margin-left: 4vw; */
  width: 72vw;
}
#lp-skill {
  border: 0;
}
#aboutme {
  font-weight: normal;
  font-style: italic;
}

#edu {
  margin-bottom: 3vh;
}

#varjo {
  font-style: italic;
  font-size: 1em;
}
#varjo:hover {
  color: rgb(69, 69, 237);
  text-decoration: underline;
}
#varjo:active {
  color: rgb(50, 50, 182);
}

@media only screen and (max-width: 600px) {
  html {
    width: 100vw;
  }
  html,
  body {
    overflow-x: hidden;
  }
  header {
    width: 100vw;
    display: flex;
  }
  .avery {
    margin-left: 0;
  }
  nav {
    width: 100vw;
    justify-content: space-between;
  }
  .a-img-text {
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
  .a-text h1,
  .a-text h2 {
    margin: 2vh 0;
  }
  .avery img {
    width: 50vw;
  }
  .socials img {
    width: 8vw;
  }
  .socials {
    /* justify-content: space-around; */
    justify-content: space-between;
  }
  .home-title,
  #lp-title-git,
  .section-title {
    width: 80vw;
    /* text-align: center; */
  }
  .displayed-projects,
  .displayed-education,
  .lp-images {
    flex-direction: column-reverse;
    width: 90vw;
  }
  .small-project,
  .small-education,
  .sp-img img,
  .lp-images img {
    width: 60vw;
  }
  .sp-img img,
  .lp-images img {
    height: 60vw;
  }
  .skill-img img {
    height: 4vh;
  }
  .skill p {
    margin: 0;
    font-size: 0.9em;
  }
  .skill {
    padding: 0.7vh;
    margin: 0.5vh 0.5vh;
  }
  .displayed-skills,
  .lp-description,
  .lp-skill {
    width: 85vw;
    justify-content: space-between;
  }
  .section-cont h3 {
    font-size: 1em;
  }
  .view-more,
  .lp-title-images,
  .section-cont {
    width: 90vw;
  }
  .sp-tech img {
    height: 3vh;
    margin: 0.5vh 0.5vh;
  }
  .f-contact {
    width: 90vw;
  }
  .footer-info {
    width: 1000vw;
  }
  .lp-title {
    width: 60vw;
  }
  .lp-github img {
    width: 10vw;
  }
  .lp-title-images {
    align-items: center;
  }
  .lp-images {
    flex-direction: column;
    align-items: center;
  }
  .section-title h2 {
    margin: 2vh 0;
  }
  .sp-title h5 {
    margin: 1vh 0;
  }
  .sp-title h3 {
    margin: 1vh 0 2vh 0;
  }
  .sp-description p {
    margin: 1.25vh 0;
  }
  .sp-tech {
    margin: 0.5vh 0;
  }
}
